<template>
  <div id="forgot-password">
    <auth-forms-wrapper show-links width="392px" card-classes="px-0">
      <template #form>
        <div id="forgot-password__wrapper">
          <v-form
            ref="resetForm"
            v-model="isFormValid"
            id="forgot-password__form"
            @submit.prevent="onSubmitHandler"
            @keydown.enter="onSubmitHandler"
          >
            <v-row no-gutters class="mb-2">
              <v-col cols="12" class="text-center w-100">
                <h3
                  class="text--primary font-weight-large secondary--font pb-2"
                  v-text="displayMessages.title"
                />

                <div id="forgot-password__subtitle">
                  <span
                    v-text="displayMessages.subTitle"
                    class="text-lg-subtitle-1 body-2 text--secondary"
                  />
                </div>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" class="pt-2">
                <div class="pb-2">
                  <alert-message
                    v-show="!!errorMessage"
                    :message="errorMessage"
                  />
                </div>
                <v-text-field
                  label="Email Address"
                  v-model="email"
                  outlined
                  v-focus
                  class="required"
                  required
                  :rules="[required('Email Address'), validateEmail]"
              /></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="pt-1">
                <v-btn
                  type="submit"
                  :disabled="!isFormValid"
                  x-large
                  block
                  :loading="isLoading"
                  class="button font-weight-bold white--text"
                  color="dark-black"
                  v-track="'forgot-password-submit-btn'"
                >
                  Reset Password
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <div class="pt-6 text-center">
            <router-link
              to="/login"
              class="text-decoration-none"
              v-track="'forgot-password-back-to-login-link'"
            >
              <strong class="subtitle-2 text--secondary font-weight-bold">
                Back to Login
              </strong>
            </router-link>
          </div>
        </div>
      </template>
    </auth-forms-wrapper>
  </div>
</template>

<script>
import { resetPasswordEmail } from "@/services/auth";
import { required, validateEmail } from "@/validators";
import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";

import { AUTH_ERROR_CASE_MESSAGES } from "@/constants/app";
import AlertMessage from "@/components/shared/AlertMessage.vue";

/**
 * Forgot Password view
 */
export default {
  name: "ForgotPassword",
  /**
   * ---------------- Components ----------------
   */
  components: {
    AuthFormsWrapper,
    AlertMessage,
  },
  /**
   * ---------------- Data properties ----------------
   */
  data() {
    return {
      email: "",
      isFormValid: false,
      isLoading: false,
      errorMessage: "",
      successMessage: "",
    };
  },
  computed: {
    /**
     * Error messages
     */
    displayMessages() {
      return {
        title: "Forgot Your Password?",
        subTitle: "No worries, just enter your registered email address!",
      };
    },
  },
  methods: {
    required,
    validateEmail,
    /**
     * Closes snackbar
     */
    closeSnackbar() {
      this.successMessage = "";
      this.errorMessage = "";
    },
    /**
     * Submits forgot password form and sends the reset password email to the user
     */
    async onSubmitHandler() {
      try {
        if (!this.isFormValid) return this.$refs.resetForm.validate();
        this.closeSnackbar();
        this.isLoading = true;

        // Sends reset password email
        await resetPasswordEmail(this.email);

        // Redirect user to reset password confirmation page
        this.$router.push({
          path: "/forgot-password-confirmation",
          query: { email: this.email },
        });
      } catch (error) {
        this.exceptionHandler(error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Handles the api errors and show user messages
     */
    exceptionHandler(error) {
      let { message, code } = error;
      if (!code) code = error.response?.data?.error?.message;

      const { commonErrorMessage } = this.$appConfig;

      message = AUTH_ERROR_CASE_MESSAGES[code] ?? commonErrorMessage;
      this.errorMessage = message;
    },
  },
};
</script>
